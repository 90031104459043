<template>
	<div v-viewer="options">
		<div v-for="field in fieldList" :key="field.id" :class="className">
			<div class="name">{{ field.field.label }}</div>
      <div v-if="field.field.type === 'file'" class="file_value">
        <div class="file_container" v-for="img in JSON.parse(field.the_value)" :key="img.url">
          <img v-if="img.fileType === 'image'" :src="img.full_url" :data-source="img.full_url" alt="">
          <img @click="openFile(img)" v-else :src="getSrc(img)">
          <span>{{ img.original_name }}</span>
        </div>
      </div>
			<div v-else class="value">{{ field.the_value }}</div>
		</div>
    <a-modal
      :title="video.title"
      :footer="null"
      :visible="visible"
      width="100%"
      @cancel="closeVideo"
    >
      <video
        id="video"
        autoplay
        :src="video.src"
        controls
        width="100%"
        height="99%"
      ></video>
    </a-modal>
	</div>
</template>
<script>
import fileTypeIcon from '@/assets/fileTypeIcon'
import 'viewerjs/dist/viewer.css'
import Viewer from 'v-viewer'
import Vue from 'vue'
Vue.use(Viewer, {
  debug: false,
  defaultOptions: {
    zIndex: 9999
  }
})
export default {
	props: {
		fieldList: {
			type: Array,
			default: () => []
		},
    className: {
      type: String,
      default: () => 'desc_item'
    }
	},
  data () {
    return {
      options: {
        toolbar: true,
        url: 'data-source'
      },
      visible: false,
      video: {
        title: '查看视频',
        src: null
      }
    }
  },
  methods: {
    closeVideo () {
      this.visible = false
      let myVideo = document.getElementById('video')
      myVideo.pause()
    },
    openFile (file) {
      if (file.fileType === 'video') {
        this.video.title = file.original_name
        this.video.src = file.full_url
        this.visible = true
      } else {
        const url = 'https://view.officeapps.live.com/op/view.aspx?src=' + file.full_url
        window.open(url)
      }
    },
    getSrc (img) {
      return fileTypeIcon[img.fileType]
    },
    getImages (list) {
      const r = []
      list.forEach(item => {
        if (item.fileType === 'image') {
          r.push(item)
        }
      })
      return r
    }
  }
}
</script>
<style lang="less" scoped>
.desc_container{
	margin-bottom: 8px;
	background-color: #fff;
	.title{
		border-left: 4px solid #3263f2;
		padding-left: 10px;
		height: 44px;
		line-height: 44px;
		font-size: 18px;
		border-bottom: 1px solid #e6e6e6;
	}
	.desc_item{
		display: flex;
		padding: 10px 10px 10px 14px;
		border-bottom: 1px solid #e6e6e6;
    font-size: 16px;
		.name{
			color: #999;
			width: 80px;
      overflow: hidden;
      white-space: nowrap;
			margin-right: 8px;
		}
		.value{
			color: #222;
			flex: 1;
			overflow: hidden;
			word-wrap:break-word; white-space:normal; word-break:break-all;
		}
	}
}
.file_value{
  flex: 1;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow: hidden;
}
.file_container{
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  span{
    flex: 1;
    height: 40px;
    line-height: 40px;
    overflow: hidden;
  }
}
img{
  width: 40px;
  height: 40px;
  margin-right: 5px;
}
.middle_item {
  display: flex;
  padding: 5px 0;
  .name{
    color: #999; 
    width: 80px;
  }
  .value{
    flex: 1;
    color: #222;
  }
}
/deep/ .ant-modal-wrap{
  z-index: 99999;
}
/deep/ .ant-modal-body{
  padding: 5px;
}
/deep/ .ant-modal{
  padding-bottom: 0;
  top: 0;
}
/deep/ .ant-modal-body{
  height: calc(100vh - 75px);
  overflow: auto;
}
</style>
