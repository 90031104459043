import doc from './doc.png'
import xls from './xls.png'
import ppt from './ppt.png'
import zip from './zip.png'
import txt from './txt.png'
import file from './file.png'
import video from './video.png'

export default {
  doc, xls, ppt, zip, txt, file, video
}
